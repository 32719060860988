import cx from 'classnames';
import { Link } from 'react-router-dom';
import { LinkProps } from 'react-router-dom';
import { t } from '../../i18n';
import { precisionRounding } from '../../utils/Math/Math';

import { Icon, PollChart, Text } from '../index';

import { Card } from './Card';

interface CardQuestionResultsProps {
  title: string;
  label?: string;
  detailLink?: LinkProps['to'];
  values: { yes: number; no: number; undecided: number; noVotes: number };
  decision?: boolean;
  isResultPublished: boolean;

  className?: string;
}

export const CardQuestionResults: React.FC<CardQuestionResultsProps> = (props) => {
  const { values, decision, isResultPublished } = props;

  const yes = precisionRounding(values.yes * 100);
  const no = precisionRounding(values.no * 100);
  const undecided = precisionRounding(values.undecided * 100);
  const noVotes = precisionRounding(values.noVotes * 100);

  const showLabel = props.label || props.detailLink;

  let decisionMessage = '';
  const hasDecision = typeof decision !== 'undefined';
  if (hasDecision && decision === true) decisionMessage = 'yes';
  if (hasDecision && decision === false) decisionMessage = 'no';

  return (
    <div className="Question">
      {showLabel && (
        <div className="CardQuestionLabel">
          {props.label && (
            <Text as="span" className="text-uppercase CardQuestionLabel__title" color="Neutral700">
              {props.label}
            </Text>
          )}
          {props.detailLink && (
            <Link className="CardQuestionLabel__link" to={props.detailLink}>
              {t('Card.Question.detailLink')} <Icon name="info" />
            </Link>
          )}
        </div>
      )}
      <Card className={cx('CardQuestionResults', props.className)}>
        <PollChart yes={yes} no={no} undecided={undecided} />

        <div className="CardQuestionResults__info">
          <Text className="CardQuestionResults__title" as="div" size="body-large" color="Blue700">
            {props.title}

            {hasDecision && isResultPublished && (
              <Text
                className="CardQuestionResults__decision"
                as="span"
                size="body-small"
                weight="600"
                color={decisionMessage === 'yes' ? 'SystemGreen' : 'SystemRed'}
              >
                {t(`Card.QuestionResults.Decision.${decisionMessage}`)}
              </Text>
            )}
          </Text>

          <div className="CardQuestionResults__results">
            <Text color="SystemGreen">
              {t('Card.QuestionResults.yes')} {yes}&nbsp;%
            </Text>
            <Text color="SystemRed">
              {t('Card.QuestionResults.no')} {no}&nbsp;%
            </Text>
            <Text color="SystemOrange">
              {t('Card.QuestionResults.undecided')} {undecided}&nbsp;%
            </Text>
            <Text color="Neutral600">
              {t('Card.QuestionResults.noVotes')} {noVotes}&nbsp;%
            </Text>
          </div>
        </div>
      </Card>
    </div>
  );
};
